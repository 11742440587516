import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";

import Header from "../components/Header";
import { Container, SmallContainer } from "../components/Grid";
import { BackgroundBox } from "../components/Account/AccountFormStyles";
import {
  StyledF7,
} from "../components/SignUp";


import { clearUpdateInterval } from "../state/actions";

import { updateUserDetails, userDetails, signOut } from "../services/moocfi";
import { createStore, syncStore } from "../state/appStore";

const SubmitButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  max-width: 280px;
  padding: 1rem;
  line-height: 2rem;
  border-radius: 2rem;
  background: #32c2a2;
  border: none;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
  display: block;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  :hover {
    cursor: pointer;
  }
	margin: 0 auto;
	margin-top:32px;
`;

const DenyButton = styled.button`
  font-size: 1rem;
  outline: none !important;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  color: ${props =>
    props.disabled ? "rgba(255,255,255,0.5)" : "rgba(255,255,255,1)"};
  max-width: 280px;
  padding: 1rem;
  line-height: 2rem;
	border-radius: 2rem;
	border:3px solid #32c2a2;
  width: ${props => (props.disabled ? "80%" : "100%")};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  transition: all 0.2s ease;
	display: block;
	background:none;
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  :hover {
    cursor: pointer;
  }
	margin: 0 auto;
	margin-top:32px;
`;



class AcceptPolicy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loadingInProgress: true,
    }
  }

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile() {
    userDetails()
      .then(user => {
        this.setState({
          user,
          loadingInProgress: false
        });
      })
      .catch(error => {
        console.log("Failed to load profile", error);
        window.location = this.props.intl.formatMessage({ id: "routes.LANDING" });
      });
  }

  denyClick = () => {

    signOut();

    const { store } = createStore();

    syncStore()
      .then(() => store.dispatch(clearUpdateInterval()))
      .catch(err => { });

    window.location = this.props.intl.formatMessage({ id: "routes.LANDING" });
  }

  acceptClick = () => {
    const user = this.state.user;
    const firstName = _.get(user, "user_field.first_name", "");
    const lastName = _.get(user, "user_field.last_name", "");
    const studentNumber = _.get(user, "user_field.organizational_id", "");
    const deadline = _.get(user, "extra_fields.deadline", "");
    const helsinki_university = _.get(
      user,
      "extra_fields.helsinki_university",
      ""
    );
    const open_university = _.get(user, "extra_fields.open_university", "");
    const research = _.get(user, "extra_fields.research", "");
    const marketing = _.get(user, "extra_fields.marketing", "");
    const email = _.get(user, "email", "");
    const language = _.get(user, "extra_fields.language", "");
    const country = _.get(user, "extra_fields.country");
    const postal_code = _.get(user, "extra_fields.postal_code", "");
    const accept_policy_versions = _.get(user, "extra_fields.accept_policy_versions");
    let update_policy = []

    if (Array.isArray(accept_policy_versions)) {
      update_policy.unshift(...accept_policy_versions)
      update_policy.unshift(process.env.PRIVACY_VERSION)
    } else {
      update_policy = [process.env.PRIVACY_VERSION]
    }

    const userField = {
      first_name: firstName,
      last_name: lastName,
      organizational_id: studentNumber
    };
    const extraFields = {
      deadline: "self-paced",
      helsinki_university: helsinki_university,
      open_university: open_university,
      research: research,
      marketing: marketing,
      language: language,
      country: country,
      postal_code: postal_code,
      accept_policy_versions: update_policy
    };
    const userInfo = {
      email: email
    };

    updateUserDetails({ userField, extraFields, userInfo }).then(
      res => {
        syncStore({ locale: language });
        window.location = this.props.intl.formatMessage({ id: "routes.LANDING" });
      }
    );
  }


  render() {
    if (this.state.loadingInProgress) {
      return (
        <BackgroundBox>
          <Header
            breadcrumbs={[
              {
                title: this.props.intl.formatMessage({ id: "globals.courseOverview" }),
                path: this.props.intl.formatMessage({ id: "routes.LANDING" })
              },
              {
                title: this.props.intl.formatMessage({ id: "globals.acceptPolicy" }),
                path: this.props.intl.formatMessage({ id: "routes.ACCOUNT_POLICY" })
              }
            ]}
          />
          <Container p={[4]}>
            <FormattedMessage id="globals.loading" />
          </Container>

        </BackgroundBox>
      )
    }



    return (
      <BackgroundBox>
        <Header
          breadcrumbs={[
            {
              title: this.props.intl.formatMessage({ id: "globals.courseOverview" }),
              path: this.props.intl.formatMessage({ id: "routes.LANDING" })
            },
            {
              title: this.props.intl.formatMessage({ id: "globals.acceptPolicy" }),
              path: this.props.intl.formatMessage({ id: "routes.ACCOUNT_POLICY" })
            }
          ]}
        />
        <SmallContainer pt={6}>
          <StyledF7>
            <FormattedMessage id="missingInfo.privacyPolicyUpdateBody" />
            <br />
            <br />
            <a target="_blank" style={{ color: "#FFFFFF" }} href={this.props.intl.formatMessage({ id: "routes.PRIVACY" })}><FormattedMessage id="missingInfo.privacyPolicyUpdateLink" /></a>
          </StyledF7>
        </SmallContainer>

        <SubmitButton type="submit" onClick={this.acceptClick}>
          {this.props.intl.formatMessage({ id: "account.accept" })}
        </SubmitButton>

        <DenyButton type="submit" onClick={this.denyClick}>
          {this.props.intl.formatMessage({ id: "account.decline" })}
        </DenyButton>


      </BackgroundBox>
    )
  }
}

export default injectIntl(AcceptPolicy);
